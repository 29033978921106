@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --primary-color: 64, 182, 231; 
        --background-color: 247 250 252;
        --secondary-color: 57 147 221;
    }
}

body {
    @apply bg-default
}


/* Toast css */
.toast-Success {
    @apply bg-green-500 text-white
}

.toast-Warning {
    @apply bg-orange-400 text-white
}

.toast-Error {
    @apply bg-red-500 text-white
}

.toast-Info {
    @apply bg-blue-400 text-white
}

.timebar {
    transform-origin: left center;
    animation: roundtime calc(var(--duration) * 1s) linear forwards;
}

.toast-animation {
    transition: transform .6s ease-in-out;
    animation: toast-in-right .3s;
}

@keyframes roundtime {
    to {
        @apply scale-x-0;
    }
}

@keyframes toast-in-right {
	from {
	  transform: translateX(100%);
	  
	}
	to {
	  transform: translateX(0);
	}
}

/* Side Navbar */

#sidebar a.active div {
    @apply bg-slate-300 text-secondary;
}

#sidebar a div {
    @apply py-3 rounded-sm uppercase font-semibold text-lg text-slate-400 hover:text-secondary hover:bg-slate-300 transition-colors duration-300;
}

/* Badges */

.badge-sm {
    @apply text-sm py-1 px-2 rounded-full
}

.badge-md {
    @apply text-base py-2 px-4 rounded-full
}

.badge-lg {
    @apply text-lg py-4 px-8 rounded-full
}

/* Status */

.status-open {
    @apply bg-blue-400
}

.status-pending {
    @apply bg-yellow-400
}

.status-cleared {
    @apply bg-green-400
}

.status-returned {
    @apply bg-red-400
}

.status-paused {
    @apply bg-red-400
}

.status-cancelled {
    @apply bg-black
}

.status-reversed {
    @apply bg-purple-400
}

.status-rejected {
    @apply bg-red-400
}

.status-error {
    @apply bg-red-400

}
.status-voided {
    @apply bg-black
}

.status-unknown {
    @apply bg-gray-500
}

